@import "responsive.scss";

/**
 * General style of the page.
 */
$color-light-background-main: #eae6e5;
$color-light-foreground-main: #12130f;
$color-dark-background-main: #25282aff;
$color-dark-foreground-main: #dddad6ff;
$color-logo: #95bf74;

@font-face {
  font-display: swap;
  font-family: LibreBaskerville;
  src: url('../fonts/LibreBaskerville-Regular.ttf');
}
@font-face {
  font-display: swap;
  font-family: LibreBaskerville;
  font-weight: bold;
  src: url('../fonts/LibreBaskerville-Bold.ttf');
}
@font-face {
  font-display: swap;
  font-family: LibreBaskerville;
  font-style: italic;
  src: url('../fonts/LibreBaskerville-Italic.ttf');
}

body {
  background-color: $color-light-background-main;
  color: $color-light-foreground-main;
  font-size: 1rem;
  font-family: 'LibreBaskerville', serif;
  margin: 0;
  text-align: justify;
  @media (prefers-color-scheme: dark) {
    background-color: $color-dark-background-main;
    color: $color-dark-foreground-main;
  }
  @include for-desktop-up {
    font-size: 1.5rem;
  }
}

a {
  color: $color-light-foreground-main;
  @media (prefers-color-scheme: dark) {
    color: $color-dark-foreground-main;
  }
}

h1 {
  font-size: 1.75rem;
  text-align: center;
  @include for-desktop-up {
    font-size: 3rem;
  }
}

h2 {
  font-size: 1.5rem;
  text-align: center;
  @include for-desktop-up {
    font-size: 2.5rem;
  }
}

h3 {
  font-size: 1.25rem;
  text-align: center;
  @include for-desktop-up {
    font-size: 2.5rem;
    text-align: left;
  }
}

.body {
  margin: 20px;
  @include for-desktop-up {
    margin: auto;
    max-width: 1480px;
  }
}

.invert-color-on-dark {
  @media (prefers-color-scheme: dark) {
    filter: invert(100%);
  }
}

/**
 * Skills.
 */
.skills {
  text-align: center;

  img {
    height: 25px;
    margin: 10px;
    @include for-desktop-up {
      height: 50px;
      margin: 20px;
    }
  }
}

/**
 * LinkedIn block.
 */
.linkedin-contact {
  margin: auto;
  max-width: 700px;

  @include for-desktop-up {
    max-width: unset;
    width: 800px;
  }

  img.logo {
    display: none;
    width: 200px;
    @include for-desktop-up {
      display: inline;
    }
  }

  img.logo-small {
    display: inline;
    margin-right: 20px;
    width: 100px;
    @include for-desktop-up {
      display: none;
    }
  }

  img.maggle {
    border: 1px rgba(0, 0, 0, 0.25) solid;
    border-radius: 50%;
    display: none;
    margin-right: 20px;
    width: 100px;
    @include for-desktop-up() {
      display: inline;
    }
  }

  p.heading {
    text-align: center;
  }

  .container {
    display: table-row;
  }

  .cta {
    background-color: rgba(0,0,0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;
    @include for-desktop-up {
      margin-left: 20px;
    }

    a {
      color: unset;
      text-decoration: unset;
    }

    p.name {
      font-weight: bold;
    }
  }

  .cta:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  .left, .right {
    display: table-cell;
    vertical-align: bottom;
  }
}

/**
 * They trust me part.
 */
.trust {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 50px;
  @include for-desktop-up {
    gap: 30px;
    margin-bottom: 100px;
  }
}

.trust-item {
  margin: auto;
  img {
    max-height: 75px;
    max-width: 100%;
    @include for-desktop-up {
      height: 150px;
    }
  }
}

/**
 * Testimonial part
 */
.testimonial-item {
  margin-bottom: 60px;
  margin-top: 30px;

  .content {
    font-style: italic;
  }
  .meta {
    text-align: right;
  }
  .author-job, .author-name {
    display: inline;
  }
  .author-name {
    font-weight: bold;
  }
}

/**
 * CV part
 */
.cv-experience, .cv-studies {
  .date, .title {
    display: inline;
  }
  .item {
    margin: 20px 0;
  }
  .title {
    font-weight: bold;
  }
}

/**
 * Experience part
 */
.experience .experience-item {
  margin-bottom: 40px;

  .expand-button, .reduce-button {
    min-width: 30px;
  }
  .title {
    font-weight: bold;
  }
  .summary {
    display: flex;
    @include for-desktop-up {
      flex-wrap: wrap;
    }
  }
  .logo {
    margin: 0 10px;
    img {
      height: 30px;
    }
  }
}

/**
 * Footer
 */
.footer {
  border-bottom: solid $color-logo 7.5px;
  font-size: 0; //This font-size is needed because without it, a space is present between the logo and the margin bottom.
  padding: 10px 10px 20px;
  @include for-desktop-up {
    padding-bottom: 0;
  }
  .logo {
    display: none;
    left: 30px;
    position: relative;
    top: 7.5px;
    vertical-align: bottom;
    @include for-desktop-up {
      display: table-cell;
    }
    img {
      height: 100px;
    }
  }
  .text {
    display: block;
    font-size: 1rem;
    position: relative;
    text-align: center;
    top: 7.5px;
    vertical-align: middle;
    width: 100%;
    @include for-desktop-up {
      display: table-cell;
      font-size: 1.5rem;
    }
  }
}