/**
 * This CSS file handles the displaying of the accordion.js lib.
 */
div[accordion] {
  .summary {
    cursor: pointer;
  }

  // Style of the detail block of the accordion when the item is reduced.
  .detail {
    overflow: hidden;
  }

  // Style of the summary block of the accordion when the item is reduced.
  .summary {
    .expand-button {
      display: inline-block;
    }
    .reduce-button {
      display: none;
    }
  }

  // Style of the summary block of the accordion when the item is expended.
  .summary.accordion-expanded {
    .expand-button {
      display: none;
    }
    .reduce-button {
      display: inline-block;
    }
  }
}