// Handle all breakpoints that manage the responsive design of the website.
// Heavily inspired from: https://medium.com/free-code-camp/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862

@mixin for-mobile-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1500px) { @content; }
}
@mixin for-wide-desktop-up {
  @media (min-width: 2000px) { @content; }
}

.two-columns {
  .left-column, .right-column {
    @include for-desktop-up {
      float: right;
      width: calc(50% - 40px);
    }
  }
  .left-column {
    @include for-desktop-up {
      float: left;
    }
  }
}
.two-columns:after {
  @include for-desktop-up {
    clear:both;
  }
}